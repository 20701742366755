<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
        <DatePicker
          type="daterange"
          placeholder="时间范围"
          @on-change="filterDate"
          style="width: 250px"
        ></DatePicker>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>

    <Divider/>

    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>

   
  </main>
</template>

<script>

import buildURL from "axios/lib/helpers/buildURL";
export default {
  data() {
    return {
     
      searches: {
        user_id: "用户ID",
        video_id:'视频ID'
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "视频ID",
          key: "video_id",
          sortable: "custom",
          align: "center",
          minWidth: 110
        },
        {
          title: "视频标题",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("span", params.row.video.title);
          }
        },
        {
          title: "用户ID",
          key: "user_id",
          sortable: "custom",
          align: "center",
          minWidth: 110
        },
        {
          title: "播放时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        
        sort: this.sort,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/videoPlayRecords/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    
  }
};
</script>